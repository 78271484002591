import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import BannerGrid from '@/components/Banner/BannerGrid'
import Wysiwyg from '@/components/Wysiwyg'
import AwardHeader from '@/components/AwardHeader'
import Seo from '@/components/Seo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
interface AwardCategoryData {
  data: {
    form: any
    page: {
      title: string
      gwContent: string
      gwContentBottom: string
      translations: any
      language: any
      featuredImage: any
      uri: string
      seo: seotype
    }
    allWpGwAwardsCustomTaxonomySingle: {
      nodes: {
        uri: string
        name: string
        description: string
      }
    }
    mainMenu: any
    wp: any
  }
}

const AwardCategoryTemplate = ({ data }: AwardCategoryData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(page?.language.slug)
  }, [])

  const { page } = data
  const { form } = data
  const { categories }: any = data
  const { mainMenu } = data
  const { wp } = data

  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  return (
    <Layout featuredImage={page.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {page.seo && (
        <Seo
          title={page?.seo?.title}
          description={page?.seo?.metaDesc}
          keywords={page?.seo?.metaKeyword}
          pathname={page?.uri}
          lang={page?.language?.slug}
          image={page?.featuredImage?.node?.localFile?.url}
          twitterTitle={page?.seo?.twitterTitle}
          twitterDescription={page?.seo?.twitterDescription}
          opengraphTitle={page?.seo?.opengraphTitle}
          opengraphDescription={page?.seo?.opengraphDescription}
          opengraphImage={page?.seo?.opengraphImage?.localFile?.url}
          twitterImage={page?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <AwardHeader heading={page.title} aside={categories?.nodes}>
        <Wysiwyg className="max-w-screen-md w-full my-10">{page.gwContent}</Wysiwyg>
      </AwardHeader>
      <Container>
        <BannerGrid banners={categories.nodes} buttonString={t('Katso lisää')} />
        <Wysiwyg className="max-w-screen-md w-full my-10">{page.gwContentBottom}</Wysiwyg>
      </Container>
    </Layout>
  )
}

export const AwardCategoryPageQuery = graphql`
  query AwardCategoryPageById(
    $id: String
    $postlang: [String]
    $menu: [WpMenuLocationEnum]
    $translang: String
    $formNum: Int
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      uri
      gwContent
      gwContentBottom
      ...featuredHeroImage
      ...langdata
      ...seo
    }
    categories: allWpGwAwardsCustomTaxonomySingle(
      filter: { language: { slug: { in: $postlang } } }
      sort: { fields: gwAwardCatOrder, order: ASC }
    ) {
      nodes {
        uri
        name
        description
      }
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $formNum }) {
      ...GravityFormFields
    }
  }
`

export default AwardCategoryTemplate
