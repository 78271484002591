import React from 'react'
import Banner from '@/components/Banner'

interface BannerGridData {
  banners?: banner
  buttonString?: string
}
type banner = {
  uri: string
  name: string
  description?: string
}
const BannerGrid = ({ banners, buttonString = 'Katso lisää' }: BannerGridData) => {
  const BannerData: any = banners
  const catleng: number = BannerData.length

  return (
    <div className="flex flex-wrap justify-between">
      {BannerData.map((item: banner, index: number) => {
        if (index + 1 === catleng && catleng % 2 !== 0) {
          return (
            <Banner
              key={index}
              className="bg-green my-2 text-latte md:flex-row flex-col p-10"
              parentClass="w-full"
              buttonParentClass="md:ml-auto md:pl-5 md:justify-end pt-6 md:pt-0"
              heading={item.name}
              blockHeading=""
              subHeading=""
              buttonText={buttonString}
              buttonLink={item.uri}
              buttonStyle="hover:bg-latte hover:text-green hover:border-latte focus:bg-latte focus:text-green focus:border-latte"
            >
              {item.description}
            </Banner>
          )
        } else {
          return (
            <Banner
              key={index}
              className="bg-green my-2 text-latte flex-col p-10"
              parentClass="md:w-bannerhalf w-full"
              buttonParentClass="pt-6 mt-auto"
              heading={item.name}
              blockHeading=""
              subHeading=""
              buttonText={buttonString}
              buttonLink={item.uri}
              buttonStyle="hover:bg-latte hover:text-green hover:border-latte focus:bg-latte focus:text-green focus:border-latte"
            >
              {item.description}
            </Banner>
          )
        }
      })}
    </div>
  )
}

export default BannerGrid
