import React from 'react'
import RoundedLink from '@/components/RoundedLink'

interface BannerData {
  className?: string
  parentClass?: string
  buttonParentClass?: string
  heading: string
  blockHeading?: string
  subHeading?: string
  children?: string
  buttonText: string
  buttonLink: string
  buttonStyle?: string
}

const Banner = ({
  className = '',
  parentClass = '',
  buttonParentClass = '',
  heading,
  blockHeading,
  subHeading,
  children,
  buttonText,
  buttonLink,
  buttonStyle = '',
}: BannerData) => {
  return (
    <div className={`banner flex ${parentClass}`}>
      {blockHeading && <h2 className="mb-4">{blockHeading}</h2>}
      <div className={`w-full flex ${className}`}>
        <div>
          <h2 className="text-2xl mb-2">{heading}</h2>
          {subHeading && <p>{subHeading}</p>}
          <p className="mt-6">{children}</p>
        </div>
        <div className={`flex items-center width-200-px flex-shrink-0 ${buttonParentClass}`}>
          {buttonLink && (
            <RoundedLink className={buttonStyle} to={buttonLink}>
              {buttonText}
            </RoundedLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default Banner
